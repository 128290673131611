import axios from "axios";
import { jwtDecode } from "jwt-decode";

const instance = axios.create({
  //baseURL: "http://localhost:9004"
  baseURL: process.env.BASE_URL
});

const redirectToLogin = () => {
const currentPath = window.location.pathname;
if (currentPath !== '/') {
  localStorage.removeItem('accessToken');
  sessionStorage.removeItem('isLogin');
  window.location.href = '/'; // Redirect to login page
}
};

instance.interceptors.request.use(
(config) => {
  const token = localStorage.getItem('accessToken');

  if (token && isTokenValid(token)) {
    config.headers['Authorization'] = 'Bearer ' + token;
  } else {
    // Token is invalid, remove it and redirect to login
    redirectToLogin();
  }

  return config;
},
(error) => {
  return Promise.reject(error);
}
);

instance.interceptors.response.use(
(response) => {
  return response;
},
(error) => {
  if (error.response && error.response.status === 401 || error.response.status === 500) {
    // Token is invalid or expired
    redirectToLogin();
  }

  return Promise.reject(error);
}
);

const isTokenValid = (token) => {
if (!token) {
  return false;
}

const decodedToken = jwtDecode(token);
const currentTime = Date.now() / 1000;

return decodedToken.exp > currentTime;
};

export default instance;
